import { FC } from 'react';
import { UserType } from '../user';

// @ts-ignore
import styles from './styles.module.css';
import { CompaignsIcon } from 'src/icons';
import { Tag } from 'antd';
import { getModelsParsedStat } from 'src/helpers/get-creators-parsed-stat.helper';
import { getDashboardStatsTagColor } from 'src/helpers/get-dashboard-stats-tag-color.helper';

interface IProps {
  row: UserType;
  labelColor: string;
}

export const RowCreators: FC<IProps> = ({ row, labelColor }) => {
  const {parsedCount, parsedError, unparsedCount, unparsedError} = getModelsParsedStat(row);
  const tagColor = getDashboardStatsTagColor(row);
  
  return (
    <div>
      {row?.chatModels?.length  ? (
        <div className={styles.creatorDescr}>
          <div className={styles.creatorDescrInner}>
            <p>{row?.chatModels?.length}</p>
            {row.chatModels?.find(
              (model) =>
                model.campaignsStats &&
                model.campaignsStats.totalSubscribers > 20,
            ) && <CompaignsIcon width={18} height={18} className="ml-4" />}
          </div>
          <Tag color={tagColor} className={styles.tagDescr}>
            {`(${parsedCount}/${parsedError}, ${unparsedCount}/${unparsedError})`}
          </Tag>
        </div>
      ) : (
        <Tag style={{ color: labelColor, borderColor: labelColor }}>
          not added
        </Tag>
      )}
    </div>
  );
};
