import { UserType } from "src/components";
import { ListItemModel } from "src/components/dashboard/typesListItem";
import { ModelStatusEnum } from "src/types";

export const getModelsParsedStat = (userData: UserType) => {
  let parsedCount = 0;
  let parsedError = 0;
  let unparsedCount = 0;
  let unparsedError = 0;
  
  userData.chatModels.map((i: ListItemModel) => {
    const isParsed =
      i.setting?.contactsCollectingEndAt && i.setting?.vaultCollectingEndAt;
    if (isParsed) {
      parsedCount += 1;
      if (i.status !== ModelStatusEnum.ACTIVE && i.status !== ModelStatusEnum.CONNECTION_LOST) {
        parsedError += 1;
      }
    } else {
      unparsedCount += 1;
      if (i.status !== ModelStatusEnum.CONFIRMED) {
        unparsedError += 1;
      }
    }
  });

  return {
    parsedCount,
    parsedError,
    unparsedCount,
    unparsedError,
  }
}
