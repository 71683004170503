import { useMutation } from '@apollo/client';
import { STORE_COMPANY_MUTATION, StoreCompanyMutation } from '../company';
import { useContext, useState } from 'react';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';
import { NotificationContext } from '../context/notification-context.component';

export const RowName = ({ rowData }: { rowData: any }) => {
  const { addNotification } = useContext(NotificationContext);

  const [storeCompany] = useMutation<StoreCompanyMutation>(
    STORE_COMPANY_MUTATION,
  );

  const [isEdit, setIsEdit] = useState(false);
  const [editedName, setEditedName] = useState<null | string>(null);

  const { company } = rowData;

  const handleEdit = async () => {
    setEditedName(editedName ? editedName : company[0].name);
    setIsEdit(true);
  };

  const handleClear = async () => {
    setIsEdit(false);
    setEditedName(null);
  };

  const handleSave = async () => {
    const { id, contactEmail, address, status } = company[0];

    try {
      await storeCompany({
        variables: {
          input: {
            name: editedName,
            id,
            contactEmail,
            address,
            status,
          },
        },
      });

      setIsEdit(false);

      addNotification({
        text: 'Success saved!',
        type: 'success',
        delay: 3000,
      });
    } catch (err) {
      addNotification({
        text: 'Error saving',
        type: 'error',
        delay: 3000,
      });
    }
  };

  return (
    <>
      {company[0] ? (
        <Space>
          {isEdit ? (
            <>
              <Input
                size="middle"
                value={editedName ?? ''}
                onChange={(e) => setEditedName(e.target.value)}
              />
              <Button
                type="text"
                shape="circle"
                onClick={handleSave}
                icon={
                  <CheckOutlined
                    style={{ color: 'rgb(46, 125, 50)', fontSize: 20 }}
                  />
                }
              />

              <Button
                type="text"
                shape="circle"
                onClick={handleClear}
                icon={
                  <CloseOutlined
                    style={{ color: 'rgb(211, 47, 47)', fontSize: 20 }}
                  />
                }
              />
            </>
          ) : (
            <>
              <CopyableElementWrapper>
                {editedName ? editedName : company[0].name}
              </CopyableElementWrapper>
              <Button
                type="text"
                shape="circle"
                onClick={handleEdit}
                icon={<EditOutlined style={{ fontSize: 20, color: 'gray' }} />}
              />
            </>
          )}
        </Space>
      ) : (
        'n/a'
      )}
    </>
  );
};
