enum CountryEnum {
  AF = 'Afghanistan',
  AL = 'Albania',
  DZ = 'Algeria',
  AD = 'Andorra',
  AO = 'Angola',
  AR = 'Argentina',
  AM = 'Armenia',
  AU = 'Australia',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BS = 'Bahamas',
  BH = 'Bahrain',
  BD = 'Bangladesh',
  BB = 'Barbados',
  BY = 'Belarus',
  BE = 'Belgium',
  BZ = 'Belize',
  BJ = 'Benin',
  BT = 'Bhutan',
  BO = 'Bolivia',
  BA = 'BosniaAndHerzegovina',
  BW = 'Botswana',
  BR = 'Brazil',
  BN = 'Brunei',
  BG = 'Bulgaria',
  BF = 'BurkinaFaso',
  BI = 'Burundi',
  KH = 'Cambodia',
  CM = 'Cameroon',
  CA = 'Canada',
  CV = 'CapeVerde',
  CF = 'CentralAfricanRepublic',
  TD = 'Chad',
  CL = 'Chile',
  CN = 'China',
  CO = 'Colombia',
  KM = 'Comoros',
  CG = 'Congo',
  CR = 'CostaRica',
  HR = 'Croatia',
  CU = 'Cuba',
  CY = 'Cyprus',
  CZ = 'CzechRepublic',
  DK = 'Denmark',
  DJ = 'Djibouti',
  DM = 'Dominica',
  DO = 'DominicanRepublic',
  TL = 'EastTimor',
  EC = 'Ecuador',
  EG = 'Egypt',
  SV = 'ElSalvador',
  GQ = 'EquatorialGuinea',
  ER = 'Eritrea',
  EE = 'Estonia',
  SZ = 'Eswatini',
  ET = 'Ethiopia',
  FJ = 'Fiji',
  FI = 'Finland',
  FR = 'France',
  GA = 'Gabon',
  GM = 'Gambia',
  GE = 'Georgia',
  DE = 'Germany',
  GH = 'Ghana',
  GR = 'Greece',
  GD = 'Grenada',
  GT = 'Guatemala',
  GN = 'Guinea',
  GW = 'GuineaBissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HN = 'Honduras',
  HU = 'Hungary',
  IS = 'Iceland',
  IN = 'India',
  ID = 'Indonesia',
  IR = 'Iran',
  IQ = 'Iraq',
  IE = 'Ireland',
  IL = 'Israel',
  IT = 'Italy',
  CI = 'IvoryCoast',
  JM = 'Jamaica',
  JP = 'Japan',
  JO = 'Jordan',
  KZ = 'Kazakhstan',
  KE = 'Kenya',
  KI = 'Kiribati',
  KW = 'Kuwait',
  KG = 'Kyrgyzstan',
  LA = 'Laos',
  LV = 'Latvia',
  LB = 'Lebanon',
  LS = 'Lesotho',
  LR = 'Liberia',
  LY = 'Libya',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  MG = 'Madagascar',
  MW = 'Malawi',
  MY = 'Malaysia',
  MV = 'Maldives',
  ML = 'Mali',
  MT = 'Malta',
  MH = 'MarshallIslands',
  MR = 'Mauritania',
  MU = 'Mauritius',
  MX = 'Mexico',
  FM = 'Micronesia',
  MD = 'Moldova',
  MC = 'Monaco',
  MN = 'Mongolia',
  ME = 'Montenegro',
  MA = 'Morocco',
  MZ = 'Mozambique',
  MM = 'Myanmar',
  NA = 'Namibia',
  NR = 'Nauru',
  NP = 'Nepal',
  NL = 'Netherlands',
  NZ = 'NewZealand',
  NI = 'Nicaragua',
  NE = 'Niger',
  NG = 'Nigeria',
  MK = 'NorthMacedonia',
  NO = 'Norway',
  OM = 'Oman',
  PK = 'Pakistan',
  PW = 'Palau',
  PA = 'Panama',
  PG = 'PapuaNewGuinea',
  PY = 'Paraguay',
  PE = 'Peru',
  PH = 'Philippines',
  PL = 'Poland',
  PT = 'Portugal',
  QA = 'Qatar',
  RO = 'Romania',
  RU = 'Russia',
  RW = 'Rwanda',
  KN = 'SaintKittsAndNevis',
  LC = 'SaintLucia',
  VC = 'SaintVincentAndTheGrenadines',
  WS = 'Samoa',
  SM = 'SanMarino',
  ST = 'SaoTomeAndPrincipe',
  SA = 'SaudiArabia',
  SN = 'Senegal',
  RS = 'Serbia',
  SC = 'Seychelles',
  SL = 'SierraLeone',
  SG = 'Singapore',
  SK = 'Slovakia',
  SI = 'Slovenia',
  SB = 'SolomonIslands',
  SO = 'Somalia',
  ZA = 'SouthAfrica',
  KR = 'SouthKorea',
  SS = 'SouthSudan',
  ES = 'Spain',
  LK = 'SriLanka',
  SD = 'Sudan',
  SR = 'Suriname',
  SE = 'Sweden',
  CH = 'Switzerland',
  SY = 'Syria',
  TW = 'Taiwan',
  TJ = 'Tajikistan',
  TZ = 'Tanzania',
  TH = 'Thailand',
  TG = 'Togo',
  TO = 'Tonga',
  TT = 'TrinidadAndTobago',
  TN = 'Tunisia',
  TR = 'Turkey',
  TM = 'Turkmenistan',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukraine',
  AE = 'UnitedArabEmirates',
  GB = 'UnitedKingdom',
  US = 'UnitedStates',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VU = 'Vanuatu',
  VA = 'VaticanCity',
  VE = 'Venezuela',
  VN = 'Vietnam',
  YE = 'Yemen',
  ZM = 'Zambia',
  ZW = 'Zimbabwe',
}

export type CountryKeysEnum = (keyof typeof CountryEnum)[];

export { CountryEnum };
