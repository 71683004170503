import { SortOrder } from 'antd/es/table/interface';

export enum SortingFieldsEnum {
  USER_NAME = 'USER_NAME',
  USER_EMAIL = 'USER_EMAIL',
  USER_STATUS = 'USER_STATUS',
  COMPANY_NAME = 'COMPANY_NAME',
  CREATORS_COUNT = 'CREATORS_COUNT',
  CREATORS_REVENUE = 'CREATORS_REVENUE',
  USER_REGISTERED_AT = 'USER_REGISTERED_AT',
  USER_LAST_LOGIN_AT = 'USER_LAST_LOGIN_AT',
  USER_EMAIL_CONFIRMED_AT = 'USER_EMAIL_CONFIRMED_AT',
  CREATOR_APPROVED_AT = 'CREATOR_APPROVED_AT',
  ACTIVITY = 'ACTIVITY',
  ROLE = 'ROLE',
}

export enum SortingModelFieldsEnum {
  MODEL_NAME = 'MODEL_NAME',
  MODEL_EMAIL = 'MODEL_EMAIL',
  MODEL_STATUS = 'MODEL_STATUS',
  COMPANY_NAME = 'COMPANY_NAME',
  MODEL_REGISTERED_AT = 'MODEL_REGISTERED_AT',
  AUTHOR = 'AUTHOR',
  CHATER = 'CHATER',
  SUBCRIPTION_TYPE = 'SUBCRIPTION_TYPE',
  TRIAL_LINK_COUNT = 'TRIAL_LINK_COUNT',
  TRACKING_LINK_COUNT = 'TRACKING_LINK_COUNT',
  IP = 'IP'
}

export enum SortingCreatorInWorkFieldsEnum {
  NAME = 'NAME',
  WORK_AT = 'WORK_AT',
  CHATER = 'CHATER',
}

export enum SortingCompanyFiledsEnum {
  COMAPNY_NAME = 'COMAPNY_NAME',
  CREATOR_COUNT = 'CREATOR_COUNT',
  CONTACT_EMAIL = 'CONTACT_EMAIL',
  CONTACT_NUMBER = 'CONTACT_NUMBER',
  AUTHOR = 'AUTHOR',
  WEBSITE = 'WEBSITE',
  ADDRESS = 'ADDRESS',
}

export enum SortingProxyFieldsEnum {
  COUNTRY = 'COUNTRY',
  HOST = 'HOST',
  PORT = 'PORT',
  STATUS = 'STATUS',
}

type SortingField = SortingFieldsEnum[
  | keyof SortingFieldsEnum
  | keyof SortingModelFieldsEnum];

export interface SortData {
  field?: SortingField;
  sort?: 'ASC' | 'DESC' | string;
}

const dumpOrder = (order?: SortOrder) => {
  if (order) {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
  }

  return order;
};

const dumpDashboardSortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'email':
      data.field = SortingFieldsEnum.USER_EMAIL;
      break;
    case 'status':
      data.field = SortingFieldsEnum.USER_STATUS;
      break;
    case 'companyName':
      data.field = SortingFieldsEnum.COMPANY_NAME;
      break;
    case 'creators':
      data.field = SortingFieldsEnum.CREATORS_COUNT;
      break;
    case 'chatModels':
      data.field = SortingFieldsEnum.CREATORS_COUNT;
      break;
    case 'revenue':
      data.field = SortingFieldsEnum.CREATORS_REVENUE;
      break;
    case 'createdAt':
      data.field = SortingFieldsEnum.USER_REGISTERED_AT;
      break;
    case 'lastLoginAt':
      data.field = SortingFieldsEnum.USER_LAST_LOGIN_AT;
      break;
    case 'emailConfirmedAt':
      data.field = SortingFieldsEnum.USER_EMAIL_CONFIRMED_AT;
      break;
    case 'approvedAt':
      data.field = SortingFieldsEnum.CREATOR_APPROVED_AT;
      break;
    case 'activity':
      data.field = SortingFieldsEnum.ACTIVITY;
      break;
    case 'username':
      data.field = SortingFieldsEnum.USER_NAME;

      break;
  }

  return { sorting: { ...data, sort: dumpOrder(sortData.order) } };
};

const dumpModelsSortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'name':
      data.field = SortingModelFieldsEnum.MODEL_NAME;
      break;
    case 'email':
      data.field = SortingModelFieldsEnum.MODEL_EMAIL;
      break;
    case 'status':
      data.field = SortingModelFieldsEnum.MODEL_STATUS;
      break;
    case 'company':
      data.field = SortingModelFieldsEnum.COMPANY_NAME;
      break;
    case 'createdAt':
      data.field = SortingModelFieldsEnum.MODEL_REGISTERED_AT;
      break;
    case 'author':
      data.field = SortingModelFieldsEnum.AUTHOR;
      break;
    case 'chatters':
      data.field = SortingModelFieldsEnum.CHATER;
      break;
    case 'subcriptionType':
      data.field = SortingModelFieldsEnum.SUBCRIPTION_TYPE;
      break;
    case 'trialLinkCount':
      data.field = SortingModelFieldsEnum.TRIAL_LINK_COUNT;
      break;
    case 'trackingLinkCount':
      data.field = SortingModelFieldsEnum.TRACKING_LINK_COUNT;
      break;
    case 'modelProxy':
      data.field = SortingModelFieldsEnum.IP;
      break;

  }

  return { sorting: { ...data, sort: dumpOrder(sortData.order) } };
};

const dumpProxySortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'country':
      data.field = SortingProxyFieldsEnum.COUNTRY;
      break;
    case 'host':
      data.field = SortingProxyFieldsEnum.HOST;
      break;
    case 'port':
      data.field = SortingProxyFieldsEnum.PORT;
      break;
    case 'status':
      data.field = SortingProxyFieldsEnum.STATUS;
      break;
  }

  return { sorting: { ...data, sort: dumpOrder(sortData.order) } };
};

const dumpCompaniesSortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'companyName':
      data.field = SortingCompanyFiledsEnum.COMAPNY_NAME;
      break;
    case 'creatorsCount':
      data.field = SortingCompanyFiledsEnum.CREATOR_COUNT;
      break;
    case 'contactEmail':
      data.field = SortingCompanyFiledsEnum.CONTACT_EMAIL;
      break;
    case 'telegramNumber':
      data.field = SortingCompanyFiledsEnum.CONTACT_NUMBER;
      break;
    case 'author':
      data.field = SortingCompanyFiledsEnum.AUTHOR;
      break;
    case 'websiteUrl':
      data.field = SortingCompanyFiledsEnum.WEBSITE;
      break;
    case 'address':
      data.field = SortingCompanyFiledsEnum.ADDRESS;
      break;
  }

  return { sorting: { ...data, sort: dumpOrder(sortData.order) } };
};

const dumpUsersSortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'username':
      data.field = SortingFieldsEnum.USER_NAME;
      break;
    case 'email':
      data.field = SortingFieldsEnum.USER_EMAIL;
      break;
    case 'status':
      data.field = SortingFieldsEnum.USER_STATUS;
      break;
    case 'roles':
      data.field = SortingFieldsEnum.ROLE;
      break;
    case 'companyName':
      data.field = SortingFieldsEnum.COMPANY_NAME;
      break;
  }

  return { sorting: { ...data, sort: dumpOrder(sortData.order) } };
};

const dumpCreatorInWorkSortParams = (sortData: any) => {
  const data: SortData = {};

  if (!sortData?.field || !sortData?.order) return data;

  switch (sortData.field) {
    case 'name':
      data.field = SortingCreatorInWorkFieldsEnum.NAME;
      break;
    case 'startWorkAt':
      data.field = SortingCreatorInWorkFieldsEnum.WORK_AT;
      break;
    case 'chatter':
      data.field = SortingCreatorInWorkFieldsEnum.CHATER;
      break;
  }

  return { sort: { ...data, sort: dumpOrder(sortData.order) } };
};

export {
  dumpDashboardSortParams,
  dumpModelsSortParams,
  dumpProxySortParams,
  dumpCompaniesSortParams,
  dumpUsersSortParams,
  dumpCreatorInWorkSortParams,
};
