export enum ProxyStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DISCONNECTED = 'DISCONNECTED',
  AVAILABLE = 'AVAILABLE',
  REMOVED = 'REMOVED'
}

export type ProxyStatusUnion = `${ProxyStatusEnum}`;

export const proxyStatusesArr = Object.values(ProxyStatusEnum);

export const proxyStatusesOptions = proxyStatusesArr
  .filter((status) => status !== ProxyStatusEnum.AVAILABLE)
  .map((status) => {
    const resLabel = status.toLowerCase();

    return {
      value: status,
      label: resLabel[0].toUpperCase() + resLabel.slice(1),
    };
  });

export const PROXY_STATUSES = [
  {
    text: 'Active',
    value: ProxyStatusEnum.ACTIVE,
  },
  {
    text: 'Available',
    value: ProxyStatusEnum.AVAILABLE,
  },
  {
    text: 'Inactive',
    value: ProxyStatusEnum.INACTIVE,
  },
  {
    text: 'Disconnected',
    value: ProxyStatusEnum.DISCONNECTED,
  },
];
