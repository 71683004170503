import { Tag } from 'antd';
import { FC } from 'react';
import { ChatModelType } from 'src/components/chatModel';
import { getCreatorStatus } from './utils/getCreatorStatus';
import { TagColorsEnum } from 'src/types/tag-color';
import cn from 'classnames';
import styles from './styles.module.css';

interface IProps {
  model: ChatModelType;
}

export const CreatorStatus: FC<IProps> = ({ model }) => {
  const statusData = getCreatorStatus(model);

  if (!statusData) {
    return null;
  }

  const { status, color } = statusData;

  const defaultColor = Object.values(TagColorsEnum).includes(
    color as TagColorsEnum,
  );

  return defaultColor ? (
    <Tag color={color} className={cn({ [styles.title]: status.length > 10 })}>
      {status}
    </Tag>
  ) : (
    <Tag
      style={{
        color: color,
        borderColor: color,
      }}
      className={cn({ [styles.title]: status.length > 15 })}>
      {status}
    </Tag>
  );
};
