import { gql } from '@apollo/client';

export const proxyDataFragment = gql`
  fragment ProxyData on Proxy {
    id
    country
    host
    port
    user
    password
    status
    customProxy
    assignedModels {
      name
    }
    company {
      name
    }
  }
`;

export const modelProxyDataFragment = gql`
fragment ModelProxyData on ModelProxy {
  id
  country
  proxyId
  fallbackProxyId
  status
  createdAt
  updatedAt
  mainProxy {
    ...ProxyData
  }
}
${proxyDataFragment}
`;
