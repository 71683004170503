import {
  RolesEnum,
  USERS_ROLES,
  USERS_STATUSES,
  UserStatusEnum,
} from 'src/types';
import { StoreUser } from './store-user.component';
import { GET_USERS_BY_PAGES_QUERY } from './queries';
import { GetUsersType, NodesType, UserType } from './types';
import { EditUser } from './edit-user.component';
import Table, { CustomColumnType } from '../table';
import { dumpUsers } from 'src/helpers/dump.helper';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';
import { RowCompanyName } from './RowCompanyName';

import styles from './User.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import PageHeader from '../base/pageHeader';
import TableHeader from '../base/tableHeader';
import { UserSearchEnum } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { Tag } from 'antd';
import { dumpUsersSortParams } from 'src/utils/dump-sort-params.utils';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';

const USERS_PAGINATION_LIMIT = 10;

export const UsersList = () => {
  const {
    setPageParams,
    setPaginationLimitParams,
    handleSetSearchParams,
    setSortParams,
    setFilterParams,
    sellsSearch,
    setSellsSearchParams,
    canClear,
    clearAllFilters,
    filters,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
  } = useSearchParamsHelper();

  const { data, pageData, loading, error, refetch, updateData } = useQueryHook<
    GetUsersType,
    UserType
  >({
    fetchPolicy: 'cache-and-network',
    query: GET_USERS_BY_PAGES_QUERY,
    variables: {
      limit: paginationLimit ? paginationLimit : USERS_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...filters,
      ...prepareSearchBySells(sellsSearch),
      ...dumpUsersSortParams({ field: sortField, order: sortOrder }),
    },
    dumpFunction: dumpUsers,
  });

  const handleNavigate = (link: string | undefined) => {
    if (!link) return;

    const url = `fanscrm://open/${link}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const COLUMNS: CustomColumnType = [
    {
      key: 'username',
      dataIndex: 'username',
      title: (
        <TableHeader
          title={'User name'}
          search
          key={UserSearchEnum.NAME}
          name={UserSearchEnum.NAME}
          searchValue={sellsSearch?.[UserSearchEnum.NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '200px',
      render: (__: any, node?: NodesType) => {
        return (
          <EditUser
            className="fcrm-greed-col truncate w-[200px]"
            userData={{ ...node }}
            updateUserInList={updateData}
            refetchUserList={refetch}
          />
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('username', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'User id',
      width: '200px',
      render: (__: any, node: NodesType) => {
        return (
          <div className="fcrm-greed-col">
            <CopyableElementWrapper>{node.id}</CopyableElementWrapper>
          </div>
        );
      },
    },
    {
      key: 'autologinKey',
      dataIndex: 'autologinKey',
      title: 'Link',
      width: '200px',
      render: (__: any, node: NodesType) => {
        return (
          <div className="fcrm-greed-col w-[200px]">
            {node?.autologinKey ? (
              <CopyableElementWrapper
                onClick={ ()=> handleNavigate(node.autologinKey)}
                classname='hover:cursor-pointer hover:underline'>
                {`fanscrm://open/${node.autologinKey}`}
              </CopyableElementWrapper>
            ) : 
              'none'
            }
          </div>
        );
      },
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: (
        <TableHeader
          title={'Email'}
          search
          key={UserSearchEnum.EMAIL}
          name={UserSearchEnum.EMAIL}
          searchValue={sellsSearch?.[UserSearchEnum.EMAIL]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '300px',
      render: (__: any, node: NodesType) => {
        return (
          <div className="fcrm-greed-col overflow-hidden whitespace-nowrap">
            <CopyableElementWrapper>{node.email}</CopyableElementWrapper>
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('email', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: '200px',
      render: (__: any, node: NodesType) => {
        return (
          <div className="fcrm-greed-col">
            {node.status === UserStatusEnum.ACTIVE && (
              <Tag color="success">ACTIVE</Tag>
            )}
            {node.status === UserStatusEnum.NOT_CONFIRMED && (
              <Tag color="processing">NOT_CONFIRMED</Tag>
            )}
            {node.status === UserStatusEnum.INACTIVE && (
              <Tag color="error">INACTIVE</Tag>
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      filters: USERS_STATUSES,
      filteredValue: filters?.status || null,
    },
    {
      key: 'roles',
      dataIndex: 'roles',
      title: 'Role',
      width: '200px',
      render: (__: any, node: NodesType) => {
        return <div className="fcrm-greed-col">{node.role}</div>;
      },
      sorter: true,
      sortOrder: checkSortOrder('roles', {
        field: sortField,
        order: sortOrder,
      }),
      filters: USERS_ROLES,
      filteredValue: filters?.roles || null,
    },
    {
      key: 'companyName',
      dataIndex: 'companyName',
      title: (
        <TableHeader
          title={'Company name'}
          search
          key={UserSearchEnum.COMPANY_NAME}
          name={UserSearchEnum.COMPANY_NAME}
          searchValue={sellsSearch?.[UserSearchEnum.COMPANY_NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: '350px',
      render: (__: any, node: NodesType) => {
        return (
          <div className="fcrm-greed-col">
            {node.role !== RolesEnum.SUPER_ADMIN && (
              <RowCompanyName
                userId={node.id}
                userName={node.username}
                companies={node.company}
              />
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: checkSortOrder('companyName', {
        field: sortField,
        order: sortOrder,
      }),
    },
  ];

  return (
    <div className={styles.container}>
      <PageHeader
        title="Users"
        setSearch={handleSetSearchParams}
        search={searchText}
        clear={canClear}
        onClear={clearAllFilters}
      />

      <StoreUser onUserStored={refetch} isPopupMode />

      {!loading && error && (
        <div className="fcrm-error-i">
          {error?.message || 'Internal error'}
        </div>
      )}

      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setFilters={setFilterParams}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : USERS_PAGINATION_LIMIT
          }
          defPageSize={USERS_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          totalCount={pageData?.totalCount}
          emptyText="No users available."
          filters={filters}
        />
      </div>
    </div>
  );
};
