import { ProxyStatusEnum } from 'src/types';
import { GetProxiesByPagesType, ProxyType } from './types';
import { GET_PROXIES_BY_PAGES_QUERY } from './queries';
import Table from '../table';
import { dumpProxies } from 'src/helpers/dump.helper';
import { EditProxy } from './edit-proxy.component';
import { StoreProxy } from './store-proxy.component';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './Proxy.module.css';
import { checkSortOrder } from 'src/utils/sort.utils';
import useQueryHook from 'src/hooks/useQueryHook.hook';
import { Tag } from 'antd';
import { dumpProxySortParams } from 'src/utils/dump-sort-params.utils';
import { CopyableElementWrapper } from '../base/copyableElementWrapper ';
import TableHeader from '../base/tableHeader';
import { ProxySearchEnum } from 'src/types/search.enum';
import { prepareSearchBySells } from 'src/utils/search.utils';
import { fromGlobalIdHelper } from 'src/helpers';
import { PROXIES_COUNTRIES_FILTER } from './filters/proxies-cuntries-filter';
import { PROXY_OWNER_FILTER } from './filters/proxy-owner-filter';

const PROXIES_PAGINATION_LIMIT = 10;

interface IProps {
  proxyStatus: ProxyStatusEnum;
  isAvailable?: boolean;
}

export const ProxyTab: React.FC<IProps> = ({
  proxyStatus,
  isAvailable = false,
}) => {
  const {
    setPageParams,
    setPaginationLimitParams,
    setSortParams,
    setSellsSearchParams,
    setFilterParams,
    searchText,
    currentPage,
    paginationLimit,
    sortField,
    sortOrder,
    sellsSearch,
    filters
  } = useSearchParamsHelper();

  const { data, pageData, loading, refetch, error } = useQueryHook<
    GetProxiesByPagesType,
    ProxyType
  >({
    query: GET_PROXIES_BY_PAGES_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      available: isAvailable,
      limit: paginationLimit ? paginationLimit : PROXIES_PAGINATION_LIMIT,
      page: currentPage,
      searchStr: searchText,
      ...filters,
      status: proxyStatus,
      isOurProxy: filters?.isOurProxy?.[0] 
      ? JSON.parse(filters.isOurProxy[0] as string) 
      : undefined,
      ...dumpProxySortParams({ field: sortField, order: sortOrder }),
      ...prepareSearchBySells(sellsSearch),
    },
    dumpFunction: dumpProxies,
  });

  const COLUMNS = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'Proxy id',
      width: 80,
      render: (__: any, proxy: ProxyType) => {
        const {id} = fromGlobalIdHelper(proxy.id);
        return (
          <div className="fcrm-greed-col w-[80px]">{id}</div>
        );
      },
    },
    {
      key: 'country',
      dataIndex: 'country',
      title: 'Country',
      width: 80,
      sorter: true,
      sortOrder: checkSortOrder('country', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, proxy: ProxyType) => {
        return (
          <EditProxy
            className="fcrm-greed-col w-[80px]"
            proxyData={{ ...proxy }}
            onRefetch={refetch}
          />
        );
      },
      filters: PROXIES_COUNTRIES_FILTER,
      filteredValue: filters?.country || null,
    },
    {
      key: 'isOurProxy',
      dataIndex: 'isOurProxy',
      title: 'Proxy owner',
      width: 120,
      render: (__: any, proxy: ProxyType) => {
        return (
          <p className="fcrm-greed-col w-[120px]">{proxy.customProxy
            ? <Tag color="processing">CUSTOMER</Tag>
            : <Tag color="success">Fans-CRM</Tag>}</p>
        );
      },
      filters: PROXY_OWNER_FILTER,
      filteredValue: filters?.isOurProxy || null,
    },
    {
      key: 'company',
      dataIndex: 'company',
      title: (
        <TableHeader
          title={'Company name'}
          search
          key={ProxySearchEnum.COMPANY_NAME}
          name={ProxySearchEnum.COMPANY_NAME}
          searchValue={sellsSearch?.[ProxySearchEnum.COMPANY_NAME]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 200,
      render: (__: any, proxy: ProxyType) => {
        return <p className='w-[200px]'>{proxy.company?.name}</p>;
      },
    },
    {
      key: 'models',
      dataIndex: 'models',
      title: 'Models',
      width: 200,
      render: (__: any, proxy: ProxyType) => {
        return (
          <div className='w-[200px]'>
            <p>Model(s) count: {proxy.assignedModels.length}</p>
            {proxy.assignedModels.map((model) => {
              return <p key={model.name}>{model.name}</p>;
            })}
          </div>
        );
      },
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: 'User',
      width: 150,
      render: (__: any, proxy: ProxyType) => {
        return (
          <CopyableElementWrapper classname="w-[150px]">
            {proxy.user}
          </CopyableElementWrapper>
        );
      },
    },
    {
      key: 'password',
      dataIndex: 'password',
      title: 'Password',
      width: 200,
      render: (__: any, proxy: ProxyType) => {
        return (
          <CopyableElementWrapper classname="w-[200px]">
            {proxy.password}
          </CopyableElementWrapper>
        );
      },
    },
    {
      key: 'host',
      dataIndex: 'host',
      title: (
        <TableHeader
          title={'Host'}
          search
          key={ProxySearchEnum.HOST}
          name={ProxySearchEnum.HOST}
          searchValue={sellsSearch?.[ProxySearchEnum.HOST]}
          setSearch={setSellsSearchParams}
        />
      ),
      width: 120,
      sorter: true,
      sortOrder: checkSortOrder('host', {
        field: sortField,
        order: sortOrder,
      }),
      render: (__: any, proxy: ProxyType) => {
        return (
          <CopyableElementWrapper classname="w-[120px]">
            {proxy.host}
          </CopyableElementWrapper>
        );
      },
    },
    {
      key: 'port',
      dataIndex: 'port',
      title: 'Port',
      width: 100,
      sorter: true,
      sortOrder: checkSortOrder('port', {
        field: sortField,
        order: sortOrder,
      }),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 150,
      sorter: true,
      sortOrder: checkSortOrder('status', {
        field: sortField,
        order: sortOrder,
      }),
      render: (status: ProxyStatusEnum) => {
        return (
          <div className='w-[150px]'>
            {status === ProxyStatusEnum.ACTIVE && (
              <Tag color="success">{status}</Tag>
            )}
            {status === ProxyStatusEnum.AVAILABLE && (
              <Tag color="processing">{status}</Tag>
            )}
            {status === ProxyStatusEnum.DISCONNECTED && (
              <Tag color="warning">{status}</Tag>
            )}
            {status === ProxyStatusEnum.INACTIVE && (
              <Tag color="error">{status}</Tag>
            )}
            {status === ProxyStatusEnum.REMOVED && (
              <Tag color="error">{status}</Tag>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <StoreProxy onProxyStored={refetch} />
      <div className={styles.tableWrapper}>
        <Table
          loading={loading}
          columns={COLUMNS}
          data={data}
          setSort={setSortParams}
          currentPage={currentPage}
          setPage={setPageParams}
          pageLimit={
            paginationLimit ? paginationLimit : PROXIES_PAGINATION_LIMIT
          }
          defPageSize={PROXIES_PAGINATION_LIMIT}
          setPaginationLimit={setPaginationLimitParams}
          totalCount={pageData?.totalCount}
          emptyText="No proxies available."
          setFilters={setFilterParams}
        />

        {!loading && error && (
          <div className="fcrm-error-i">
            {error?.message || 'Internal error'}
          </div>
        )}
      </div>
    </>
  );
};
