import { gql } from '@apollo/client';
import { companyDataFragment } from '../company';
import { platformDataFragment } from '../platform';
import { modelProxyDataFragment, proxyDataFragment } from '../proxy';
import { userDataFragment } from '../user';
import {
  chatModelDataFragment,
  chatModelInWorkDataFragment,
} from './fragments';

export const GET_CHAT_MODELS_QUERY = gql`
  query getChatModelsByPages(
    $page: Int!
    $limit: Int!
    $name: String
    $email: String
    $status: [ModelStatusEnum!]
    $sorting: ModelSortingInput
    $searchStr: String
    $searchBy: [SearchByChatModelInput!]
    $subcriptionType: [SubsribeTypeEnum!]
  ) {
    ChatModelGroup {
      getChatModelsByPages(
        page: $page
        limit: $limit
        name: $name
        email: $email
        status: $status
        sorting: $sorting
        searchStr: $searchStr
        searchBy: $searchBy
        subcriptionType: $subcriptionType
      ) {
        nodes {
          ...ChatModelData
          author {
            ...UserData
          }
          company {
            ...CompanyData
          }
          platform {
            ...PlatformData
          }
          proxy {
            ...ProxyData
          }
          modelProxy {
            ...ModelProxyData
          }
          assignedUsers {
            ...UserData
          }
        }
        pageInfo {
          pageNumber
          totalPages
          totalCount
          filterCount
        }
      }
    }
  }
  ${chatModelDataFragment}
  ${userDataFragment}
  ${companyDataFragment}
  ${platformDataFragment}
  ${proxyDataFragment}
  ${modelProxyDataFragment}
`;

export const GET_PUBLIC_KEY_QUERY = gql`
  query getPubKey {
    getPubKey
  }
`;

export const GET_CHAT_MODELS_IN_WORK_QUERY = gql`
  query getAssignedUsersInWork(
    $before: ID
    $after: ID
    $first: Int
    $last: Int
  ) {
    ChatModelGroup {
      getAssignedUsersInWork(
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            ...ChatModelInWorkData
            user {
              ...UserData
            }
            model {
              ...ChatModelData
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${chatModelInWorkDataFragment}
  ${userDataFragment}
  ${chatModelDataFragment}
`;

export const GET_CHAT_MODELS_IN_WORK_BY_PAGES_QUERY = gql`
  query getAssignedUsersInWorkByPages(
    $page: Int!
    $limit: Int!
    $searchStr: String
    $searchBy: [ChatModelInWorkSearchByInput!]
    $sort: ChatModelInWorkSortInput
  ) {
    ChatModelGroup {
      getAssignedUsersInWorkByPages(
        page: $page
        limit: $limit
        searchStr: $searchStr
        searchBy: $searchBy
        sort: $sort
      ) {
        nodes {
          ...ChatModelInWorkData
          user {
            ...UserData
          }
          model {
            ...ChatModelData
          }
        }
        pageInfo {
          pageNumber
          totalPages
          totalCount
        }
      }
    }
  }
  ${chatModelInWorkDataFragment}
  ${userDataFragment}
  ${chatModelDataFragment}
`;
