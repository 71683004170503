export const PROXY_OWNER_FILTER = [
  {
    text: 'Fans-CRM',
    value: true,
  },
  {
    text: 'CUSTOMER',
    value: false,
  }
];
