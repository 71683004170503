import { ModelStatusEnum, ModelSubsribeEnum } from 'src/types';

const CREATOR_STATUSES = [
  {
    text: 'Active',
    value: ModelStatusEnum.ACTIVE,
  },
  {
    text: 'Confirmed',
    value: ModelStatusEnum.CONFIRMED,
  },
  {
    text: 'Inactive',
    value: ModelStatusEnum.INACTIVE,
  },
  {
    text: 'New',
    value: ModelStatusEnum.NEW,
  },
  {
    text: 'Connection lost',
    value: ModelStatusEnum.CONNECTION_LOST,
  },
];

const CREATOR_SUBSCRIPTION = [
  {
    text: 'FREE',
    value: ModelSubsribeEnum.FREE,
  },
  {
    text: 'PAID',
    value: ModelSubsribeEnum.PAID,
  },
];

export { CREATOR_STATUSES, CREATOR_SUBSCRIPTION };
