import {
  createContext,
  useState,
  useCallback,
  FC,
  ReactNode,
} from 'react';
import { Snackbar, Alert, SnackbarOrigin } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export type NotificationType = {
  text: string;
  type: 'info' | 'error' | 'success' | 'warning';
  delay: number;
  position?: SnackbarOrigin;
};

export type NotificationContextType = {
  notifications: (NotificationType & { id: string })[];
  addNotification: (val: NotificationType) => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  addNotification: () => {},
});

export const NotificationContextComponent: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<
    (NotificationType & { id: string })[]
  >([]);

  const addNotification = useCallback((notification: NotificationType) => {
    setNotifications((prev) => [
      ...prev,
      {
        ...notification,
        id: uuidv4(),
        position: {
          vertical: notification?.position?.vertical || 'bottom',
          horizontal: notification?.position?.horizontal || 'left',
        },
      },
    ]);
  }, []);

  const closeNotification = useCallback((id: string) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id),
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
      }}>
      {children}
      {notifications.map(({ text, delay, type, id, position }) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={delay}
          onClose={() => closeNotification(id)}
          anchorOrigin={{
            vertical: position?.vertical || 'bottom',
            horizontal: position?.horizontal || 'left',
          }}>
          <Alert severity={type} onClose={() => closeNotification(id)}>
            {text}
          </Alert>
        </Snackbar>
      ))}
    </NotificationContext.Provider>
  );
};
