import { OptionType } from './option.type';

export enum ModelStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  CONNECTION_LOST = 'CONNECTION_LOST',
}

export type ModelStatusUnion = `${ModelStatusEnum}`;

export const modelStatusesArr = Object.values(ModelStatusEnum);

export const modelStatusesOptions: OptionType[] = modelStatusesArr.map(
  (status) => {
    let resLabel = status.toLowerCase();

    if (resLabel.indexOf('_') !== -1) {
      const tmpArr = resLabel.split('_');

      resLabel = `${tmpArr[0]} ${tmpArr[1]}`;
    }

    return {
      value: status,
      label: resLabel[0].toUpperCase() + resLabel.slice(1),
    };
  },
);
