import { FC, ReactNode, useContext, useRef, useState } from 'react';
import { NotificationContext } from 'src/components/context/notification-context.component';
import cn from 'classnames';
import styles from './styles.module.css';

interface IProps {
  children: ReactNode;
  copyText?: string;
  classname?: string;
  onClick?: () => void;
}

export const CopyableElementWrapper: FC<IProps> = ({
  children,
  copyText,
  classname = '',
  onClick,
}) => {
  const { addNotification } = useContext(NotificationContext);
  const [clickTimer, setClickTimer] = useState<NodeJS.Timeout | null>(null);
  const isDoubleClickRef = useRef(false);

  const handleShowNotify = (text: string) => {
    addNotification({
      text,
      type: 'success',
      delay: 3000,
      position: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const handleCopy = () => {
    const contentToCopy = typeof children === 'string' ? children : copyText;

    if (!contentToCopy) return;

    navigator.clipboard.writeText(contentToCopy).then(() => {
      handleShowNotify('Copied successfully.');
    });
  };

  const handleSingleClick = () => {
    if (onClick) onClick();
  };

  const handleClick = () => {
    if (clickTimer) {
      clearTimeout(clickTimer);
    }

    setClickTimer(
      setTimeout(() => {
        if (!isDoubleClickRef.current) {
          handleSingleClick();
        }
        isDoubleClickRef.current = false;
      }, 300),
    );
  };

  const handleDoubleClick = () => {
    if (clickTimer) {
      clearTimeout(clickTimer);
    }
    isDoubleClickRef.current = true;
    handleCopy();
    isDoubleClickRef.current = false;
  };

  return (
    <div
      className={cn(styles.wrapper, { [classname]: classname })}
      onDoubleClick={handleDoubleClick}
      onClick={handleClick}>
      {children}
    </div>
  );
};
