import { UserType } from 'src/components';
import { getModelsParsedStat } from './get-creators-parsed-stat.helper';
import { RowColorParsedStat } from 'src/types/row-color.enum';
import { LiteralUnion } from 'antd/es/_util/type';
import { PresetColorType, PresetStatusColorType } from 'antd/es/_util/colors';

export const getDashboardStatsTagColor = (
  row: UserType
): LiteralUnion<PresetColorType | PresetStatusColorType> => {
  const { parsedCount, parsedError, unparsedCount, unparsedError } =
    getModelsParsedStat(row);
  let tagColor = RowColorParsedStat.DEFAULT;

  if (row.chatModels?.length) {
    if (row.chatModels?.length === parsedCount && !parsedError) {
      tagColor = RowColorParsedStat.SUCCESS;
    } else if (unparsedCount > 0 && !unparsedError) {
      tagColor = RowColorParsedStat.WARNING;
    } else if (parsedError || unparsedError) {
      tagColor = RowColorParsedStat.ERROR;
    }
  }

  return tagColor;
};
