export enum CompanySearchEnun {
  NAME = 'NAME',
  CREATOR_COUNT = 'CREATOR_COUNT',
  CONTACT_EMAIL = 'CONTACT_EMAIL',
  CONTACT_NUMBER = 'CONTACT_NUMBER',
  AUTHOR = 'AUTHOR',
  WEBSITE = 'WEBSITE',
  ADDRESS = 'ADDRESS',
}

export enum UserSearchEnum {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  STATUS = 'STATUS',
  ROLE = 'ROLE',
  COMPANY_NAME = 'COMPANY_NAME',
}

export enum ChatModelSearchEnum {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  COMPANY_NAME = 'COMPANY_NAME',
  AUTHOR = 'AUTHOR',
  STATUS = 'STATUS',
  CHATER = 'CHATER',
  ID = 'ID',
  TRIAL_LINK_COUNT = 'TRIAL_LINK_COUNT',
  TRACKING_LINK_COUNT = 'TRACKING_LINK_COUNT',
  IP = 'IP'
}

export enum ModelInWorkSearchEnum {
  NAME = 'NAME',
  CHATER = 'CHATER',
}

export enum ProxySearchEnum {
  COMPANY_NAME = 'COMPANY_NAME',
  HOST = 'HOST',
}
