import { Tabs } from 'src/components/tabs';
import { ProxyTab } from './proxy-tab.component';
import { ProxyStatusEnum } from 'src/types';
import { useSearchParamsHelper } from 'src/hooks/useSearchParamsHelper';

import styles from './Proxy.module.css';
import PageHeader from '../base/pageHeader';

export const ProxyList = () => {
  const { handleSetSearchParams, setTabChangeParams, searchText, currentTab } =
    useSearchParamsHelper();

  return (
    <div className={styles.container}>
      <PageHeader
        title="Proxies"
        setSearch={handleSetSearchParams}
        search={searchText}
        clear
      />

      <Tabs
        handleChange={setTabChangeParams}
        activeTab={currentTab ? currentTab : 'ACTIVE'}
        tabs={[
          {
            id: 'ACTIVE',
            title: 'Active',
            status: ProxyStatusEnum.ACTIVE,
            content: (
              <ProxyTab
                proxyStatus={ProxyStatusEnum.ACTIVE}
                key={ProxyStatusEnum.ACTIVE}
              />
            ),
          },
          {
            id: 'AVAILABLE',
            title: 'Available',
            status: ProxyStatusEnum.ACTIVE,
            content: (
              <ProxyTab
                proxyStatus={ProxyStatusEnum.ACTIVE}
                key={ProxyStatusEnum.AVAILABLE}
                isAvailable={true}
              />
            ),
          },
          {
            id: 'INACTIVE',
            title: 'Inactive',
            status: ProxyStatusEnum.INACTIVE,
            content: (
              <ProxyTab
                proxyStatus={ProxyStatusEnum.INACTIVE}
                key={ProxyStatusEnum.INACTIVE}
              />
            ),
          },
          {
            id: 'REMOVED',
            title: 'Removed',
            status: ProxyStatusEnum.REMOVED,
            content: (
              <ProxyTab
                proxyStatus={ProxyStatusEnum.REMOVED}
                key={ProxyStatusEnum.REMOVED}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
