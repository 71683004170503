import { ChatModelType } from "src/components/chatModel";
import { YELLOW } from "src/constants/colors.constants";
import { ModelStatusEnum } from "src/types";
import { AuthStateEnum } from "src/types/auth-state.enum";
import { ProxyStateEnum } from "src/types/proxy-state.enum";
import { TagColorsEnum} from "src/types/tag-color";

const STATUS_DATA = {
  NEW: {
    status: 'NEW',
    color: TagColorsEnum.ERROR,
  },
  NEW_PROXY_CONNECTION_ERROR: {
    status: 'NEW:PROXY_CONNECTION_ERROR',
    color: TagColorsEnum.ERROR,
  },
  NEW_CAPTCHA_WRONG: {
    status: 'NEW:CAPTCHA_WRONG',
    color: TagColorsEnum.ERROR,
  },
  NEW_2FA_WRONG: {
    status: 'NEW:2FA_WRONG',
    color: TagColorsEnum.ERROR,
  },
  NEW_FAILED_CRED: {
    status: 'NEW:FAILED_CREDENTIALS',
    color: TagColorsEnum.ERROR,
  },
  FAN: {
    status: 'FAN',
    color: YELLOW,
  },
  CONFIRMED: {
    status: 'CONFIRMED',
    color: TagColorsEnum.PROCESSING,
  },
  ACTIVE: {
    status: 'ACTIVE',
    color: TagColorsEnum.SUCCESS,
  },
  ACTIVE_OUTDATED: {
    status: 'ACTIVE:OUTDATED',
    color: YELLOW,
  },
  CONNECTION_LOST_PROXY_ERROR: {
    status: 'CONNECTION_LOST:PROXY_CONNECTION_ERROR',
    color: YELLOW,
  },
  CONNECTION_LOST_CAPTCHA_WRONG: {
    status: 'CONNECTION_LOST:CAPTCHA_WRONG',
    color: YELLOW,
  },
  CONNECTION_LOST_2FA_WRONG: {
    status: 'CONNECTION_LOST:2FA_WRONG',
    color: YELLOW,
  },
  CONNECTION_LOST_FAILED_CRED: {
    status: 'CONNECTION_LOST:FAILED_CREDENTIALS',
    color: YELLOW,
  },
  INACTIVE: {
    status: 'INACTIVE',
    color: TagColorsEnum.DEFAULT,
  }
}

export const getCreatorStatus = (model: ChatModelType): {status: string, color: string | TagColorsEnum } | null => {
  if (model.status === ModelStatusEnum.INACTIVE) {
    return STATUS_DATA.INACTIVE
  }

  if (model.status === ModelStatusEnum.NEW) {

    if (model.proxyConnectState === ProxyStateEnum.CONNECTION_ERROR) {
      return STATUS_DATA.NEW_PROXY_CONNECTION_ERROR;
    }

    if (model.proxyConnectState === ProxyStateEnum.GOOD) {
      if (model.authState === AuthStateEnum.CAPTCHA_WRONG) {
        return STATUS_DATA.NEW_CAPTCHA_WRONG;
      }
  
      if (model.authState === AuthStateEnum.TWO_WRONG) {
        return STATUS_DATA.NEW_2FA_WRONG;
      }
  
      if (model.authState === AuthStateEnum.FAILED_CREDENTIALS) {
        return STATUS_DATA.NEW_FAILED_CRED
      }
    }

    return STATUS_DATA.NEW;
  }

  if (model.canEarn === true) {

    if (model.proxyConnectState === ProxyStateEnum.CONNECTION_ERROR) {
      return STATUS_DATA.CONNECTION_LOST_PROXY_ERROR;
    }

    if (model.proxyConnectState === ProxyStateEnum.GOOD) {
      if (
        model.status === ModelStatusEnum.CONFIRMED &&
        (model.authState === AuthStateEnum.CAPTHCA_OK || model.authState === AuthStateEnum.TWO_OK)
      ) {
        return STATUS_DATA.CONFIRMED;
      }

      if (model.status === ModelStatusEnum.ACTIVE) {
        if (model.authState === AuthStateEnum.OUTDATED) {
          return STATUS_DATA.ACTIVE_OUTDATED;
        }

        return STATUS_DATA.ACTIVE
      }
    
      if (
        model.status === ModelStatusEnum.CONNECTION_LOST &&
        model.authState === AuthStateEnum.CAPTCHA_WRONG
      ) {
        return STATUS_DATA.CONNECTION_LOST_CAPTCHA_WRONG;
      }
    
      if (
        model.status === ModelStatusEnum.CONNECTION_LOST &&
        model.authState === AuthStateEnum.TWO_WRONG
      ) {
        return STATUS_DATA.CONNECTION_LOST_2FA_WRONG;
      }
    
      if (
        model.status === ModelStatusEnum.CONNECTION_LOST &&
        model.authState === AuthStateEnum.FAILED_CREDENTIALS
      ) {
        return STATUS_DATA.CONNECTION_LOST_FAILED_CRED;
      }
    }

  }

  if (model.canEarn === false) {
    return STATUS_DATA.FAN;
  }

  return null;
};
