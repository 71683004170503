import * as yup from 'yup';
import cn from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { Buffer } from 'buffer';
import NodeRSA from 'node-rsa';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  DropDownMenuPlacementEnum,
  ModelStatusEnum,
  modelStatusesOptions,
  OptionType,
  ProxyStatusEnum,
  RolesEnum,
  StatusEnum,
} from 'src/types';
import { chatModelDataFragment } from './fragments';
import { AssignModelToUserMutation, ChatModelType } from './types';
import {
  getAddjustedFormPosition,
  getCompanyOptionsList,
  getPlatformOptionsList,
  getProxyOptionsList,
  getUserOptionsList,
  handleClickOutside,
} from 'src/helpers';
import {
  BaseSyntheticEvent,
  createRef,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  companyDataFragment,
  GET_COMPANIES_BY_PAGES_QUERY,
  GetCompaniesByPagesType,
  CompanyType,
} from '../company';
import {
  GetPlatformsType,
  GET_PLATFORMS_QUERY,
  platformDataFragment,
  PlatformEnum,
  platformsOptions,
} from '../platform';
import {
  proxyDataFragment,
  ProxyType,
  GET_PROXIES_BY_PAGES_QUERY,
  GetProxiesByPagesType,
  modelProxyDataFragment,
} from '../proxy';
import { AuthContext } from '../context.component';
import { AddButton } from '../buttons';
import { config } from 'src/configs/config';

import styles from './store-chat-model.module.css';
import { GET_USERS_QUERY, GetSingleUserType, userDataFragment } from '../user';
import { useScrollQuery } from 'src/hooks/useScrollQuery.hook';
import { dumpCompanies, dumpProxies } from 'src/helpers/dump.helper';
import { Flex, Spin } from 'antd';

const COMPANIES_LIMIT = 20;
const PLATFORMS_LIMIT = 500;
const PROXIES_LIMIT = 20;
const CHATTERS_LIMIT = 500;

type CookieType = {
  name: string;
  value: string;
  domain: string;
  hostOnly: boolean;
  path: string;
  secure: boolean;
  httpOnly: boolean;
  session?: boolean;
  expirationDate?: number;
  sameSite: string;
};

type InputsType = {
  id?: string;
  name: string;
  email: string;
  password?: string;
  os: OptionType;
  companyId: OptionType;
  platformId: OptionType;
  proxyId: OptionType;
  status: OptionType;
  userId: OptionType;
};

type StoreChatModelMutation = {
  storeChatModel: ChatModelType;
};

export const STORE_CHAT_MODEL_MUTATION = gql`
  mutation storeChatModel($input: StoreChatModelInput!) {
    storeChatModel(input: $input) {
      ...ChatModelData
      company {
        ...CompanyData
      }
      platform {
        ...PlatformData
      }
      proxy {
        ...ProxyData
      }
      modelProxy {
        ...ModelProxyData
      }
    }
  }
  ${chatModelDataFragment}
  ${companyDataFragment}
  ${platformDataFragment}
  ${proxyDataFragment}
  ${modelProxyDataFragment}
`;

export const SEND_SUCCESS_VERIFICATION_MODEL_EMAIL = gql`
  mutation sendModelAccessProvidedToTheAppEmail(
    $input: UserEmailModelIdInput!
  ) {
    sendModelAccessProvidedToTheAppEmail(input: $input)
  }
`;

const ASSIGN_MODEL_TO_USER_MUTATION = gql`
  mutation assignModel($input: AssignModelToUserInput!) {
    assignModel(input: $input) {
      ...ChatModelData
      assignedUsers {
        ...UserData
      }
    }
  }
  ${userDataFragment}
  ${chatModelDataFragment}
`;

export const StoreChatModel = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    edit?: boolean;
    modelId?: string | null;
    companyId?: string | null;
    companyEmail?: string | null;
    platformId?: string | null;
    proxyId?: string | null;
    defaultValues?: Partial<ChatModelType> | null;
    previewOnly?: boolean;
    maxTopPosition?: number;
    publicKey: string | null;
    onModelStored?: (chatModelData: ChatModelType) => void;
    onClose?: () => void;
  }
>(
  (
    {
      edit,
      modelId,
      companyId,
      companyEmail,
      platformId,
      defaultValues,
      publicKey,
      onModelStored,
      onClose,
    },
    ref: ForwardedRef<HTMLDivElement> | undefined,
  ) => {
    const { currentUser } = useContext(AuthContext);
    const [showAddChatModel, setShowAddChatModel] = useState(!!edit);
    const [dropDownMenuPlacement, setDropDownMenuPlacement] =
      useState<DropDownMenuPlacementEnum>(DropDownMenuPlacementEnum.BOTTOM);
    const [currPlatformErrorMsg, setCurrPlatformErrorMsg] = useState('');
    const [currSelOs, setcurrSelOs] = useState<string>(
      defaultValues?.platform?.platform || PlatformEnum.UNKNOWN,
    );
    const formRef: RefObject<HTMLDivElement> = createRef();
    const backdrop = useRef<HTMLDivElement>(null);

    const [modelCompany, setModelCompany] = useState<
      string | null | undefined
    >(companyId);

    const [cookies, setCookies] = useState<CookieType[]>([]);
    const [cookieError, setCookieError] = useState('');

    const {
      register,
      handleSubmit,
      reset,
      control,
      setValue,
      resetField,
      formState: { errors, isDirty },
    } = useForm<InputsType>({
      resolver: yupResolver(
        yup.object().shape({
          id: yup.string().notRequired(),
          name: yup.string().required('Name of a model is required'),
          email: yup.string().required('Email is required').email(),
          password: edit
            ? yup.string().notRequired()
            : yup.string().required('Password is required'),
          companyId: yup
            .object()
            .shape({
              value: yup.string().required(),
              label: yup.string().required(),
            })
            .required('Company is required'),
          os: yup.mixed().notRequired(),
          platformId: yup.mixed().notRequired(),
          proxyId: yup
            .object()
            .shape({
              value: yup.string().required(),
              label: yup.string().required(),
            })
            .notRequired(),
          status: yup
            .object()
            .shape({
              value: yup.string().required(),
              label: yup.string().required(),
            })
            .notRequired(),
        }),
      ),
      defaultValues: {
        ...(defaultValues ? defaultValues : {}),
        status:
          defaultValues?.status &&
          defaultValues.status === ModelStatusEnum.INACTIVE
            ? { value: ModelStatusEnum.INACTIVE, label: 'Inactive' }
            : defaultValues?.status &&
              defaultValues.status === ModelStatusEnum.NEW
            ? { value: ModelStatusEnum.NEW, label: 'New' }
            : defaultValues?.status &&
              defaultValues.status === ModelStatusEnum.CONFIRMED
            ? { value: ModelStatusEnum.CONFIRMED, label: 'Confirmed' }
            : defaultValues?.status &&
              defaultValues.status === ModelStatusEnum.CONNECTION_LOST
            ? {
                value: ModelStatusEnum.CONNECTION_LOST,
                label: 'Connection lost',
              }
            : { value: ModelStatusEnum.ACTIVE, label: 'Active' },
        ...(modelId ? { id: modelId } : {}),
        companyId: defaultValues?.company
          ? {
              value: defaultValues?.company?.id || undefined,
              label: defaultValues.company.name,
            }
          : undefined,
        proxyId: defaultValues?.modelProxy?.mainProxy
          ? {
              value: defaultValues.modelProxy?.mainProxy.id,
              label: `${defaultValues.modelProxy?.mainProxy.country} ${defaultValues.modelProxy?.mainProxy.host}`,
            }
          : undefined,
        platformId: defaultValues?.platform
          ? {
              value: defaultValues?.platform?.id || undefined,
              label: defaultValues.platform?.userAgent,
            }
          : undefined,
        os: defaultValues?.platform
          ? {
              value: defaultValues?.platform?.platform || undefined,
              label: defaultValues.platform?.platform,
            }
          : undefined,
        userId: undefined,
      },
    });

    const {
      data: companyData,
      handleScroll: handelScrollCompanies,
      loading: companyLoading,
      lazyLoadding: companyLazyLoading,
      error: companyError,
    } = useScrollQuery<GetCompaniesByPagesType, CompanyType>({
      query: GET_COMPANIES_BY_PAGES_QUERY,
      fetchPolicy: 'network-only',
      skip: !showAddChatModel,
      limit: COMPANIES_LIMIT,
      dumpFunction: dumpCompanies,
    });

    const {
      data: windowsPlatformData,
      loading: windowsPlatformLoading,
      error: windowsPlatformError,
    } = useQuery<GetPlatformsType>(GET_PLATFORMS_QUERY, {
      variables: { first: PLATFORMS_LIMIT, platform: PlatformEnum.WINDOWS },
    });

    const {
      data: macOSPlatformData,
      loading: macOSPlatformLoading,
      error: macOSPlatformError,
    } = useQuery<GetPlatformsType>(GET_PLATFORMS_QUERY, {
      variables: { first: PLATFORMS_LIMIT, platform: PlatformEnum.MAC_OS },
    });

    const {
      data: androidPlatformData,
      loading: androidPlatformLoading,
      error: androidPlatformError,
    } = useQuery<GetPlatformsType>(GET_PLATFORMS_QUERY, {
      variables: { first: PLATFORMS_LIMIT, platform: PlatformEnum.ANDROID },
    });

    const {
      data: proxiesData,
      handleScroll,
      loading: proxyLoading,
      lazyLoadding: proxyLazyLoading,
      error: proxyError,
    } = useScrollQuery<GetProxiesByPagesType, ProxyType>({
      query: GET_PROXIES_BY_PAGES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        available: true,
        status: ProxyStatusEnum.ACTIVE,
        companyId: modelCompany ? modelCompany : null,
      },
      skip: !showAddChatModel,
      limit: PROXIES_LIMIT,
      resetOnChange: (variables, prevVariables) =>
        variables.companyId !== prevVariables?.companyId,
      dumpFunction: dumpProxies,
    });

    const [storeChatModel, { data, loading, error }] =
      useMutation<StoreChatModelMutation>(STORE_CHAT_MODEL_MUTATION);
    const [sendModelAccessProvidedToTheAppEmail] = useMutation<{
      sendModelAccessProvidedToTheAppEmail: boolean;
    }>(SEND_SUCCESS_VERIFICATION_MODEL_EMAIL);

    const { data: chattersListData, loading: loadingChattersListData } =
      useQuery<GetSingleUserType>(GET_USERS_QUERY, {
        variables: { first: CHATTERS_LIMIT, status: StatusEnum.ACTIVE },
        skip: !edit,
      });

    const [
      assignModel,
      { loading: assignModelLoading, error: assignModelError },
    ] = useMutation<AssignModelToUserMutation>(ASSIGN_MODEL_TO_USER_MUTATION);

    const displayForm = () => {
      if (!showAddChatModel) {
        setShowAddChatModel(true);
      }
    };
    const outsideClickAction = () => {
      if (showAddChatModel) {
        setShowAddChatModel(false);
      }
    };
    const send = async (
      formData: InputsType,
      e?: BaseSyntheticEvent,
    ): Promise<void> => {
      e?.preventDefault();

      if (!loading) {
        await storeChatModel({
          variables: {
            input: {
              ...(formData.id ? { id: formData.id } : {}),
              ...(formData.password ? { password: formData.password } : {}),
              name: formData.name,
              email: formData.email,
              companyId: formData.companyId.value,
              platformId: formData.platformId?.value,
              proxyId: formData.proxyId.value,
              status: formData.status.value,
              ...(cookies ? { cookies } : {}),
            },
          },
        });

        if (
          defaultValues?.status !== ModelStatusEnum.ACTIVE &&
          formData.status.value === ModelStatusEnum.ACTIVE &&
          currentUser?.role === RolesEnum.SUPER_ADMIN
        ) {
          await sendModelAccessProvidedToTheAppEmail({
            variables: {
              input: { email: companyEmail, modelId: formData.id },
            },
          });
        }

        if (!assignModelLoading && formData.userId?.value) {
          await assignModel({
            variables: {
              input: {
                userId: formData.userId.value,
                modelId: formData.id,
              },
            },
          });
        }

        setCookies([]);
        setCookieError('');
      }
    };
    const getPlatformOptionsListByOs = (os: string): OptionType[] => {
      if (os === PlatformEnum.UNKNOWN) {
        return [];
      }

      if (
        os === PlatformEnum.WINDOWS &&
        !windowsPlatformLoading &&
        windowsPlatformData
      ) {
        return getPlatformOptionsList(windowsPlatformData);
      } else if (
        os === PlatformEnum.ANDROID &&
        !androidPlatformLoading &&
        androidPlatformData
      ) {
        return getPlatformOptionsList(androidPlatformData);
      } else if (
        os === PlatformEnum.MAC_OS &&
        !macOSPlatformLoading &&
        macOSPlatformData
      ) {
        return getPlatformOptionsList(macOSPlatformData);
      }

      return [];
    };
    const getPlatformErrorMessage = (os: string): string => {
      if (os === PlatformEnum.UNKNOWN) {
        return '';
      }

      if (
        os === PlatformEnum.WINDOWS &&
        !windowsPlatformLoading &&
        windowsPlatformError
      ) {
        return windowsPlatformError.message;
      } else if (
        os === PlatformEnum.ANDROID &&
        !androidPlatformLoading &&
        androidPlatformError
      ) {
        return androidPlatformError.message;
      } else if (
        os === PlatformEnum.MAC_OS &&
        !macOSPlatformLoading &&
        macOSPlatformError
      ) {
        return macOSPlatformError.message;
      }

      return '';
    };
    const addjustFormPositions = () => {
      const currRef = (ref || formRef) as RefObject<HTMLDivElement>;

      if (!currRef || !currRef.current) {
        return;
      }

      const adjustedPos = getAddjustedFormPosition(currRef.current, 130);

      if (adjustedPos.dropDownMenuPlacement !== dropDownMenuPlacement) {
        setDropDownMenuPlacement(adjustedPos.dropDownMenuPlacement);
      }
    };

    const onShowPasswordBtnClick = () => {
      if (publicKey && defaultValues?.encPassword) {
        const rsa = new NodeRSA();

        rsa.importKey(Buffer.from(publicKey, 'base64').toString(), 'public');

        const resModelPassword = rsa.decryptPublic(
          defaultValues.encPassword,
          'base64',
        );

        setValue(
          'password',
          Buffer.from(resModelPassword, 'base64').toString(),
        );
      }
    };

    const onChooseCookiesFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        setCookieError('');

        if (!event.target?.result) {
          setCookieError('No data in the file');

          return;
        }

        let resCookies: Partial<CookieType>[] = [];

        try {
          resCookies = JSON.parse(event.target.result.toString());

          if (!resCookies.length) {
            setCookieError('Empty array with cookies');

            return;
          }

          const filteredCookies: CookieType[] = resCookies.filter(
            (cookie): cookie is CookieType => !!cookie.name && !!cookie.domain,
          );

          if (!filteredCookies.length) {
            setCookieError('Wrong format of cookies (no name and domain)');

            return;
          }

          setCookies(
            filteredCookies.map<CookieType>((cookie) => ({
              ...cookie,
              name: cookie.name,
              domain: cookie.domain,
              value: cookie.value || '',
              path: cookie.path || '/',
              secure: cookie.secure || false,
              hostOnly: cookie.hostOnly || false,
              httpOnly: cookie.httpOnly || false,
              sameSite: cookie.sameSite || 'unspecified',
            })),
          );
        } catch (err) {
          setCookieError('Wrong file format');
        }
      };
      fileReader.readAsText(e.target.files[0], 'UTF-8');

      e.preventDefault();
    };

    useEffect(() => {
      if (
        !windowsPlatformLoading &&
        !androidPlatformLoading &&
        !macOSPlatformLoading
      ) {
        const currErrMsg = getPlatformErrorMessage(currSelOs);

        if (currErrMsg) {
          setCurrPlatformErrorMsg(currErrMsg);
        }

        if (!isDirty) {
          if (defaultValues?.platform) {
            const resPlatform = platformsOptions.find(
              (option) => option.value === defaultValues.platform?.platform,
            );

            if (resPlatform) {
              setValue('os', resPlatform);
              setcurrSelOs(resPlatform.value);
            }
          }
          if (
            (windowsPlatformData ||
              androidPlatformData ||
              macOSPlatformData) &&
            platformId
          ) {
            const platformData = [
              ...(windowsPlatformData?.Dictionary.getPlatforms.edges || []),
              ...(androidPlatformData?.Dictionary.getPlatforms.edges || []),
              ...(macOSPlatformData?.Dictionary.getPlatforms.edges || []),
            ];
            const resPlatform = platformData.find(
              (platform) => platform.node.id === platformId,
            );

            if (resPlatform) {
              setValue('platformId', {
                value: resPlatform.node.id,
                label: resPlatform.node.userAgent,
              });
            }
          }
        }
      }
    }, [windowsPlatformData, androidPlatformData, macOSPlatformData]);

    useEffect(() => {
      addjustFormPositions();

      if (!edit) {
        document.addEventListener('mousedown', (e) =>
          handleClickOutside(e, formRef, outsideClickAction),
        );

        return () => {
          document.removeEventListener('mousedown', (e) =>
            handleClickOutside(e, formRef, outsideClickAction),
          );
        };
      }
    }, []);
    useEffect(() => {
      if (!loading && !error && data?.storeChatModel) {
        if (onModelStored) {
          onModelStored(data.storeChatModel);
        }

        if (!edit) {
          setShowAddChatModel(false);
        }

        reset();
      }
    }, [data]);

    if (currentUser && currentUser.role == RolesEnum.CHATTER) {
      return <></>;
    }

    const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (backdrop?.current === e.target && onClose) {
        onClose();
      }

      setShowAddChatModel(false);
    };

    return (
      <div className="relative">
        {!edit && (
          <AddButton
            btnText="Add creator"
            className="mb-2.5"
            onClick={displayForm}
          />
        )}
        {showAddChatModel && (
          <div className={styles.dialog}>
            <div
              className={cn(styles.content, {
                [styles.small]: edit,
              })}
              ref={ref || formRef}>
              <h3 className="fcrm-h3">
                {!edit ? 'Add new creator' : defaultValues?.name}
              </h3>
              {!loading && error && (
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2"></div>
                  <div className="fcrm-error-i">{error.message}</div>
                </div>
              )}
              <form className="pt-5" onSubmit={handleSubmit(send)}>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Name:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Model name"
                      className="fcrm-form-d-item"
                      {...register('name')}
                    />
                    {errors && errors.name && (
                      <div className="fcrm-error-i">{errors.name.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Email:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      placeholder="Email"
                      className="fcrm-form-d-item"
                      {...register('email')}
                    />
                    {errors && errors.email && (
                      <div className="fcrm-error-i">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Password:</div>
                  <div className="w-2/3 pb-4">
                    <input
                      type={publicKey ? 'text' : 'password'}
                      placeholder="Password"
                      className="fcrm-form-d-item"
                      {...register('password')}
                    />
                    {errors && errors.password && (
                      <div className="fcrm-error-i">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                  {publicKey && (
                    <div
                      className="pl-2 pt-3 text-blue-700 underline"
                      onClick={onShowPasswordBtnClick}>
                      show
                    </div>
                  )}
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Company:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="companyId"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            //@ts-ignore
                            options={[
                              ...getCompanyOptionsList(companyData),
                              ...(companyLazyLoading
                                ? [
                                    {
                                      value: '',
                                      label: (
                                        <Flex
                                          align="center"
                                          gap="middle"
                                          justify="center">
                                          <Spin size="small" />
                                        </Flex>
                                      ),
                                      isDisabled: true,
                                    },
                                  ]
                                : []),
                            ]}
                            menuPlacement={dropDownMenuPlacement}
                            maxMenuHeight={config.maxDropDownMenuHeight}
                            onMenuScrollToBottom={handelScrollCompanies}
                            isLoading={companyLoading}
                            onChange={(e) => {
                              if (e) {
                                resetField('companyId');
                                setValue('companyId', e);
                                setModelCompany(e.value);
                              }
                            }}
                          />
                        );
                      }}
                    />
                    {!companyLoading && companyError && (
                      <div className="fcrm-error-i">
                        {companyError.message}
                      </div>
                    )}
                    {errors && errors.companyId && (
                      <div className="fcrm-error-i">
                        {errors.companyId.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Operation System:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="os"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={platformsOptions}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                          onChange={(e) => {
                            if (e) {
                              resetField('platformId');
                              setValue('os', e);
                              setcurrSelOs(e.value);
                            }
                          }}
                        />
                      )}
                    />
                    {errors && errors.os && (
                      <div className="fcrm-error-i">{errors.os.message}</div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Browser:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="platformId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={getPlatformOptionsListByOs(currSelOs)}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                        />
                      )}
                    />
                    {!!currPlatformErrorMsg && (
                      <div className="fcrm-error-i">
                        {currPlatformErrorMsg}
                      </div>
                    )}
                    {errors && errors.platformId && (
                      <div className="fcrm-error-i">
                        {errors.platformId.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Proxy:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="proxyId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          //@ts-ignore
                          options={[
                            ...getProxyOptionsList(proxiesData),
                            ...(proxyLazyLoading
                              ? [
                                  {
                                    value: '',
                                    label: (
                                      <Flex
                                        align="center"
                                        gap="middle"
                                        justify="center">
                                        <Spin size="small" />
                                      </Flex>
                                    ),
                                    isDisabled: true,
                                  },
                                ]
                              : []),
                          ]}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                          onMenuScrollToBottom={handleScroll}
                          isLoading={proxyLoading}
                        />
                      )}
                    />
                    {!proxyLoading && proxyError && (
                      <div className="fcrm-error-i">{proxyError.message}</div>
                    )}
                    {errors && errors.proxyId && (
                      <div className="fcrm-error-i">
                        {errors.proxyId.message}
                      </div>
                    )}
                  </div>
                </div>
                {!edit && (
                  <div className="flex flex-row items-start">
                    <div className="w-1/4 pt-0.5">
                      Cookies from a JSON file:
                    </div>
                    <div className="w-2/3 pb-4">
                      <input
                        title="file"
                        type="file"
                        accept=".json,application/json,text/json"
                        onChange={onChooseCookiesFile}
                      />
                      {cookieError && (
                        <div className="fcrm-error-i">{cookieError}</div>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-row items-start">
                  <div className="w-1/4 pt-2">Status:</div>
                  <div className="w-2/3 pb-4">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={modelStatusesOptions}
                          menuPlacement={dropDownMenuPlacement}
                          maxMenuHeight={config.maxDropDownMenuHeight}
                        />
                      )}
                    />
                    {errors && errors.status && (
                      <div className="fcrm-error-i">
                        {errors.status.message}
                      </div>
                    )}
                  </div>
                </div>
                {edit ? (
                  <div className="flex flex-row items-start">
                    <div className="w-1/4 pt-2">Assign a user:</div>
                    <div className="w-2/3 pb-4">
                      <Controller
                        name="userId"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              !loadingChattersListData &&
                              chattersListData?.UserGroup?.getUsers?.edges &&
                              chattersListData.UserGroup.getUsers.edges
                                .length > 0
                                ? getUserOptionsList(
                                    chattersListData.UserGroup.getUsers.edges.map(
                                      (edge) => {
                                        if (
                                          edge.node.company?.[0]?.id ===
                                            defaultValues?.company?.id &&
                                          !defaultValues.assignedUsers?.find(
                                            (user) => user.id === edge.node.id,
                                          )
                                        ) {
                                          return {
                                            ...edge.node,
                                          };
                                        }

                                        return {};
                                      },
                                    ),
                                  )
                                : ([
                                    { value: '', label: 'LOADING...' },
                                  ] as OptionType[])
                            }
                            menuPlacement={dropDownMenuPlacement}
                            maxMenuHeight={config.maxDropDownMenuHeight}
                          />
                        )}
                      />
                      {errors && errors.userId && (
                        <div className="fcrm-error-i">
                          {errors.userId.message}
                        </div>
                      )}
                      {!assignModelLoading && assignModelError && (
                        <div className="fcrm-error-i">
                          {assignModelError.message}
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {(!edit || isDirty) && (
                  <button
                    type="submit"
                    className="fcrm-submit w-28 bg-blue-500 py-2.5 my-1.5 rounded-full m-auto hover:bg-blue-600 active:bg-blue-700">
                    Save
                  </button>
                )}
              </form>
            </div>
            <div
              className={styles.backdrop}
              ref={backdrop}
              onClick={handleClose}
            />
          </div>
        )}
      </div>
    );
  },
);
