import { gql } from '@apollo/client';
import { proxyDataFragment } from './fragments';

export const GET_PROXIES_QUERY = gql`
  query getProxies(
    $before: ID
    $after: ID
    $first: Int
    $last: Int
    $status: StatusEnum
    $available: Boolean
    $companyId: ID
  ) {
    Dictionary {
      getProxies(
        before: $before
        after: $after
        first: $first
        last: $last
        status: $status
        available: $available
        companyId: $companyId
      ) {
        edges {
          node {
            ...ProxyData
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${proxyDataFragment}
`;

export const GET_PROXIES_BY_PAGES_QUERY = gql`
  query getProxiesByPages(
    $page: Int!
    $limit: Int!
    $status: ProxyStatusEnum
    $available: Boolean
    $searchStr: String
    $sorting: SotringProxyInput
    $searchBy: [SearchProxyInput!]
    $country: [CountryEnum!]
    $isOurProxy: Boolean
  ) {
    Dictionary {
      getProxiesByPages(
        page: $page
        limit: $limit
        status: $status
        available: $available
        searchStr: $searchStr
        sorting: $sorting
        searchBy: $searchBy
        country: $country
        isOurProxy: $isOurProxy
      ) {
        nodes {
          ...ProxyData
        }
        pageInfo {
          pageNumber
          totalPages
          totalCount
        }
      }
    }
  }
  ${proxyDataFragment}
`;
