import { useState, useCallback } from 'react';
import { useQuery, DocumentNode, WatchQueryFetchPolicy } from '@apollo/client';
import useUpdateEffect from './useUpdateEffect.hook';
import isEqual from 'fast-deep-equal';
import debounce from 'lodash/debounce';
import { PaginationType } from 'src/types';

interface UseScrollQueryProps<TData> {
  query: DocumentNode;
  variables?: Record<any, any>;
  limit: number;
  resetOnChange?: (
    variables: Record<any, any>,
    prevVariables: Record<any, any> | null,
  ) => boolean;
  dumpFunction: (data: TData) => {
    list: any;
    pageInfo: PaginationType | null;
  };
  fetchPolicy?: WatchQueryFetchPolicy;
  update?: boolean;
  skip?: boolean;
}

export const useScrollQuery = <TResponse, TList>({
  query,
  variables = {},
  limit,
  resetOnChange = () => false,
  dumpFunction,
  update,
  fetchPolicy,
  skip,
}: UseScrollQueryProps<TResponse>) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLazyLoading, setIsLazyLoading] = useState<boolean>(false);
  const [data, setData] = useState<TList[]>([]);
  const [pageData, setPageData] = useState<PaginationType | null>(null);
  const [page, setPage] = useState<number>(1);
  const [currentVariables, setCurrentVariables] =
    useState<Record<any, any>>(variables);

  const { loading, error, refetch } = useQuery<TResponse>(query, {
    fetchPolicy: fetchPolicy ?? 'no-cache',
    variables: { ...currentVariables, page, limit },
    skip,
    onCompleted: (response: any) => {
      const { list, pageInfo } = dumpFunction(response);

      setData((prev) => {
        if (pageInfo?.pageNumber === 1) {
          return list;
        }
        return [...prev, ...list];
      });

      setPageData(pageInfo);
      setIsLoading(false);
      setIsLazyLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useUpdateEffect(() => {
    if (isEqual(currentVariables, variables)) return;

    setIsLoading(true);

    if (resetOnChange(variables, currentVariables)) {
      setPage(1);
      setData([]);
    }

    setCurrentVariables(variables);
  }, [currentVariables, variables, resetOnChange]);

  useUpdateEffect(() => {
    if (update) {
      setIsLoading(true);
      setData([]);
      setPage(1);
    }
  }, [update]);

  const handleScroll = useCallback(
    debounce((e: any) => {
      console.log(e);
      if (loading || !data || !pageData || !data.length) {
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } =
        e?.currentTarget || e.target;

      if (
        scrollTop + 10 >= scrollHeight - clientHeight &&
        !loading &&
        !isLazyLoading &&
        !isLoading &&
        page < pageData?.totalPages
      ) {
        setIsLazyLoading(true);
        setPage((prev) => prev + 1);
      }
    }, 100),
    [loading, data, pageData, isLazyLoading, isLoading, page],
  );

  const onRefetch = async () => {
    if (page === 1) {
      await refetch();
    } else {
      setPage(1);
    }
  };

  return {
    data,
    pageData,
    handleScroll,
    updateData: setData,
    onRefetch,
    loading: (isLoading || loading) && !isLazyLoading,
    lazyLoadding: isLazyLoading,
    error,
  };
};
